import React from "react";
import {Container} from "react-bootstrap";

interface ViewProps {
    title: string
    logo?: string
    cover?: string
    description?: string
    showTitle?: boolean
    desItalic?: boolean
}

export const Cover : React.FunctionComponent<ViewProps> = (props) => {
    const { title, description, cover, showTitle, desItalic } = props

    const styleObj = React.useMemo( () => {
        if ( cover === '' || cover === undefined ) return {
            minHeight: '0',
            padding: '0',
            marginBottom: '60px',
        }

        return {
            background: `transparent url(${ cover }) no-repeat center center`,
            backgroundSize:'cover',
        }
    }, [cover] );

    return (
        <React.Fragment>
            <div style={ styleObj }
                 className="cover_container d-flex align-items-center justify-content-center"/>
            <Container fluid={false}>
                { ( showTitle ) ? (<h1 className="title-cover">{ title }</h1>) : null }
                <p className={desItalic ? "text-cover fst-italic": "text-cover"}>
                    { description }
                </p>

            </Container>
        </React.Fragment>
    );
}