import React from "react";
import {Category} from "./types";
import {Stack} from "react-bootstrap";

interface ViewProps {
    category: Category
}

export const ItemCategory:React.FunctionComponent<ViewProps> = (props) => {
    const {category} = props

    const desc = React.useMemo( ()=>{
        const d : string[] = [];

        category.description?.split('\n').map( el =>{
            if( el.trim().length > 0 ){
                d.push( el )
            }
            return true
        }  )

        return d
    }, [category.description] )

    return (
        <div className="item-category-short">
            <Stack className="item-category-data" direction="vertical">
                <div className="me-auto">
                    <div className="item-category-title">{category.name}</div>
                    <div className="item-category-text">
                        { desc.map( (el,index) => <p key={index}>{el}</p> ) }
                    </div>
                </div>
                <div className="item-category-div">&nbsp;</div>
                <a style={{minWidth:'100px'}} className="btn btn-outline-dark" href={ category.file } target="_blank" rel="noreferrer">
                    MENÚ
                </a>
            </Stack>
            <div className="item-category-separator" />
        </div>
    )
}