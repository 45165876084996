import React from "react";
import { Container, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {useItemListState} from "../../Context/ItemListProvider/ItemListProvider";
import {useNavigate} from "react-router-dom";

interface ViewProps {
    brandLogo: string
    searchTerm: string
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>

    title: string
}
export const NavBarPage:React.FunctionComponent<ViewProps> = (props)=>{
    const { itemList } = useItemListState()
    const navigate = useNavigate()

    const handleClick = React.useCallback( (e:React.MouseEvent<HTMLElement, MouseEvent>, href:string) => {
        e.preventDefault()
        navigate(href)
    }, [ navigate ] )

    const NavDropdownItems = React.useMemo( ()=>{
        if( itemList ){
            const items = itemList.items
            return items.map( item => {
                const href = `/sales_point${ item.path }`
                return (
                    <NavDropdown.Item href={href} key={href} onClick={ e => handleClick(e, href) }>
                        { item.name }
                    </NavDropdown.Item>
                )
            } )
        }

        return []
    }, [itemList, handleClick] )

    return (
        <Navbar expand="lg" variant="light" bg="light" sticky="top" >
            <Container fluid={false}>
                <Navbar.Brand href="#" onClick={ e => handleClick(e, '/') } >
                    <img
                        src={ props.brandLogo }
                        height="40"
                        className="d-inline-block align-top"
                        alt="App Logo"
                    />
                </Navbar.Brand>
                <Navbar.Text className="d-lg-none">
                    <Nav
                        className="nav-title me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px', width:'100%' }}
                        navbarScroll
                    >
                        { props.title }
                    </Nav>
                </Navbar.Text>


                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="nav-title me-auto my-2 my-lg-0 d-none d-lg-block"
                            style={{ maxHeight: '100px', width:'100%' }}
                            navbarScroll
                        >
                            { props.title }
                        </Nav>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Búsqueda"
                            className="me-0"
                            style={ {minWidth: '250px'} }
                            aria-label="Search"
                            value={ props.searchTerm }
                            onChange={ (text)=>{
                                props.setSearchTerm( text.target.value )
                            } }
                        />
                    </Form>
                    <Nav>
                        <NavDropdown title="Puntos de Venta" id="navbarScrollingDropdown">
                            { NavDropdownItems }
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}