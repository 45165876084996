import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ClientContextProvider } from 'react-fetching-library';
import {Client} from "./Components/api/Client";
import Home from "./Pages/Home";
import {ItemListProvider} from "./Context/ItemListProvider/ItemListProvider";
import SalesPoint from "./Pages/SalesPoint";

function App() {
  global.Buffer = global.Buffer || require('buffer').Buffer;
  return (

        <ClientContextProvider client={Client}>
            <ItemListProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Home />} />
                      <Route path="/sales_point/:slug" element={<SalesPoint />} />
                  </Routes>
                </BrowserRouter>
            </ItemListProvider>
        </ClientContextProvider>

  );
}

export default App;
