import React from "react";
import {Item} from "../Components/types";
import {useItemListState} from "../Context/ItemListProvider/ItemListProvider";
import {useParams} from "react-router-dom";
import {Loading} from "../Components/Loading";
import {Container} from "react-bootstrap";
import {ItemData} from "../Components/ItemData";
import {Layout} from "./Layout";

const SalesPoint: React.FunctionComponent = (_props) => {
    const [bandLogo, setBandLogo] = React.useState<string>('')
    const [title, setTitle] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [selectedItem, setSelectedItem] = React.useState<Item|undefined>(undefined)

    const [cover, setCover] = React.useState<string>('')
    const [searchTerm, setSearchTerm] = React.useState<string>('')

    const { itemList } = useItemListState()
    let { slug } = useParams();

    React.useEffect( ()=>{
        if (itemList) {
            let item:Item|undefined = undefined;
            let filterItems = itemList.items.filter( item => item.path === '/' + slug )

            if( filterItems.length > 0 ){
                item = filterItems[0]
            }

            setSelectedItem( item )
            setBandLogo( itemList.logo )
        }

    }, [ itemList, slug ] )

    React.useEffect( ()=>{
        if( selectedItem ){
            setTitle( selectedItem.name )
            setDescription( selectedItem.description )
            setCover( (selectedItem.cover)?selectedItem.cover:'' )
        }else{
            setTitle( '' )
            setDescription( '' )
            setCover('')
        }

    }, [ selectedItem ] )

    if( selectedItem === undefined ){
        return <Loading message="Elemento no encontrado" />
    }

    return (
        <Layout title={ title }
                brandLogo={ bandLogo }
                searchTerm={ searchTerm }
                setSearchTerm={ setSearchTerm }
                description={ description }
                cover={ cover }
        >

            <Container>
                <ItemData item={ selectedItem } searchTerm={searchTerm} />
            </Container>

        </Layout>
    )
}

export default SalesPoint