import React from "react";
import {NavBarPage} from "../Components/Navbar/NavBarPage";
import {Cover} from "../Components/CoverHead/Cover";
import {Col, Container, Row} from "react-bootstrap";

interface ViewProps {
    children?: React.ReactNode;

    title: string
    brandLogo:string
    description:string
    searchTerm:string
    setSearchTerm:React.Dispatch<React.SetStateAction<string>>

    cover?: string
    coverItalic?: boolean
}

export const Layout:React.FunctionComponent<ViewProps> = (props) => {
    const { brandLogo, searchTerm, setSearchTerm, children, description, cover, coverItalic } = props
    return (
        <React.Fragment>
            <NavBarPage title="" brandLogo={ brandLogo } searchTerm={ searchTerm } setSearchTerm={ setSearchTerm } />
            <Cover title={ brandLogo } description={ description } cover={ cover } desItalic={ coverItalic } />
            <div className="page-body">
                { children }
            </div>
            <Container fluid>
                <Row className="footer">
                    <Col className="text-cover"> © Yucatan Country Club, 2023</Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}