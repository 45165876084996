import React from "react";

interface ViewProps {
    message: string
}

export const Loading:React.FunctionComponent<ViewProps> = (props) =>{
    return (
        <div>
            <main>
                <h1>{ props.message }</h1>
            </main>
        </div>
    )
}