import React from "react";
import {Item} from "../Components/types";
import {useItemListState} from "../Context/ItemListProvider/ItemListProvider";
import {Cover} from "../Components/CoverHead/Cover";
import {Col, Container, Row} from "react-bootstrap";
import {Layout} from "./Layout";
import {useNavigate} from "react-router-dom";


const Home: React.FunctionComponent = (_props) => {
  const [bandLogo, setBandLogo] = React.useState<string>('')
  const [title, setTitle] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('Descripción cargado por JSON')
  const [cover, setCover] = React.useState<string>('')

  const [items, setItems] = React.useState<Item[]>([])

  const [filteredItems, setFilteredItems] = React.useState<Item[]>([])
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const { itemList } = useItemListState()
    let navigate = useNavigate()

  const salesPoint = React.useMemo( ()=>{
    return filteredItems.map( item => {
      return (
          <Col className="item-home-data">
            <div className="card-container" onClick={ () => { navigate(`/sales_point${ item.path }`) } }>
            <Cover cover={ item.cover } title={ item.name } description={ item.description } showTitle/>
            </div>
          </Col>
      )
    } )
  },  [filteredItems, navigate] )


  React.useEffect( ()=>{
    if (itemList) {
      setTitle(itemList.name)
      setBandLogo(itemList.logo)
      setDescription( itemList.description )
      setItems( itemList.items )
      const cover = ( itemList.cover ) ? itemList.cover : ''
      setCover( cover )
    }

  }, [ itemList ] )

  React.useEffect( ()=>{
    const term = searchTerm.toLowerCase().trim()
    if( term === ''){
      setFilteredItems( items )
    }else{
      setFilteredItems(
          items.filter( item => {
            let initialFlag =  item.name.toLowerCase().includes(term) || item.description.toLowerCase().includes(term)

            item.categories.map( category => {
              initialFlag = initialFlag || category.name.toLowerCase().includes(term)
              if( category.description ){
                initialFlag = initialFlag || category.description.toLowerCase().includes(term)
              }
              return true
            } )
            return initialFlag
          })
      )
    }
  }, [items, searchTerm] )

  return (
      <Layout title={title}
              brandLogo={ bandLogo }
              searchTerm={ searchTerm }
              setSearchTerm={ setSearchTerm }
              description={ description }
              cover={ cover }
              coverItalic
      >

          <Container>
            <h1 className="big-title">Gastronomía</h1>
            <div className="big-title-after" />
            <Row xs={1} lg={2} className="home-sales-point">
              { salesPoint }
            </Row>

          </Container>

      </Layout>
  )
}

export default Home
