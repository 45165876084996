import React from "react";
import {useQuery} from "react-fetching-library";
import {ItemList} from "../../Components/types";
import {getItemList} from "../../Components/api/actions/getItemList";

interface ViewProps {
    children?: React.ReactNode | undefined
}

interface ContextValues{
    loading: boolean,
    itemList: ItemList|undefined
}

const ItemListStateContext = React.createContext<ContextValues>({loading: false, itemList:undefined} );

export function useItemListState(){
    const context = React.useContext(ItemListStateContext);
    if (context === undefined) {
        throw new Error("useItemListState must be used within a AlertProvider");
    }

    return context;
}

export const ItemListProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { loading, payload } = useQuery(getItemList);

    return (
        <ItemListStateContext.Provider value={ {loading:loading, itemList:payload} }>
            { props.children }
        </ItemListStateContext.Provider>
    )
}