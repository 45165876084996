import React from "react";
import {Item} from "./types";
import {ItemCategory} from "./ItemCategory";
import {Stack} from "react-bootstrap";

interface ViewProps {
    item: Item
    searchTerm?: string
}

export const ItemData:React.FunctionComponent<ViewProps> = (props) =>{
    const { item, searchTerm } = props

    const categories = React.useMemo( ()=>{
        let categories = item.categories

        if( searchTerm !== undefined && searchTerm.trim() !== '' ){
            const term = searchTerm.toLowerCase().trim()
            categories = categories.filter( category => {
                let initialFlag = category.name.toLowerCase().includes(term)
                if( category.description ){
                    initialFlag = initialFlag || category.description.toLowerCase().includes(term)
                }

                return initialFlag
            } )
        }

        return categories.map( category => <ItemCategory key={ item.path + '-' + category.name } category={category} /> )
    }, [ item, searchTerm ] )

    return (
        <React.Fragment>

            <Stack className="col-md-8 mx-auto">
                { categories }
            </Stack>
        </React.Fragment>
    )
}